import React from "react";
import { Link } from "react-router-dom";
import shortid from "shortid";
import { useState } from "react";
import { FaBars } from "react-icons/fa";

function Header() {
  const [showMenu, setShowMenu] = useState("md:hidden");

  const menuItems = [
    { id: shortid.generate(), title: "Projects", address: "/projects" },
    { id: shortid.generate(), title: "Contact", address: "/contact" },
    { id: shortid.generate(), title: "Resume", address: "/resume" },
  ];

  const pathName = window.location.pathname;

  return (
    <div className="fixed top-0 left-0 right-0 z-50">
      <div className="navbar flex flex-row justify-between bg-secondary">
        <div className="flex justify-between w-full">
          <div>
            <Link
              to="/"
              className="btn btn-outline mx-1 normal-case text-3xl md:text-2xl sm:text-xl"
            >
              <div className="text-sky-800 sm:hidden">
                Balasegaran Subramamiam
              </div>
              <div className="text-sky-800 sm:flex hidden">S Segaran</div>
            </Link>
          </div>
          <div className="hidden md:flex text-3xl btn btn-primary justify-center">
            <FaBars
              onClick={() => {
                if (showMenu === "md:hidden") {
                  setShowMenu("");
                } else {
                  setShowMenu("md:hidden");
                }
              }}
            />
          </div>
        </div>
        <div className="flex-none md:hidden">
          <ul className="menu menu-horizontal p-0 ">
            {menuItems.map((item) => {
              return (
                <li key={item.id}>
                  <Link
                    className={`mx-1 btn-primary ${
                      item.address === pathName && `btn-active`
                    }`}
                    to={item.address}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div>
        <div
          className={`navbar hidden md:flex md:justify-center bg-neutral-content ${showMenu}`}
        >
          <ul className="menu menu-horizontal p-0 flex justify-around w-full">
            {menuItems.map((item) => {
              return (
                <li key={item.id}>
                  <Link
                    className={`btn-sm btn-accent text-sm ${
                      item.address === pathName && `btn-active`
                    }`}
                    to={item.address}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
